"use client";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useRouter, useSearchParams } from "next/navigation";
import Image from "next/image";
import { Grid, Stack, Typography } from "@mui/material";
import SubmitButtonComponent from "@/components/buttons/SubmitButtonComponent";
import { useFormik } from "formik";
import NumberIdModal from "./NumberIdModal";
import NumberFoundModal from "./NumberFoundModal";
import OtpModal from "./OtpModal";
import {
  useGenerateOtpMutation,
  useGetContactHintQuery,
  useVerifyotpMutation,
} from "@/lib/store";
import LoaderComponent from "@/components/loader/LoaderComponent";
import PhoneNumberModal from "./PhoneNumberModal";
import { enqueueSnackbar } from "notistack";
import { NUMBER_REGEX, errorMessageText } from "@/lib/Constants";
import { moengageInitialize } from "@/lib/moengage/moengageEventsFunction";
import moengage from "@moengage/web-sdk";

const initialValue = {
  appId: "",
};
const validationSchema = Yup.object().shape({
  appId: Yup.string()
    .required("Application Id is Required")
    .length(10, "Application Id should be 10 Digits"),
});

const LoginScreen = () => {
  //HOOKS
  const router = useRouter();
  const searchParams = useSearchParams();
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState(false);
  const [openNumberIdModal, setOpenNumberIdModal] = useState(false);
  const [openNumberFoundModal, setOpenNumberFoundModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [check, setCheck] = useState(true);
  const [appId, setAppId] = useState("");

  const [resendOtp, setResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlId, setUrlId] = useState("");

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: (values) => {
      //   generateOtp({ phone: values?.phone });
    },
  });

  const {
    data: contactHintData,
    isFetching: contactHintFetching,
    isLoading: contactHintLoading,
    error: contactHintError,
    isError: isContactHintError,
    isSuccess: contactHintIsSuccess,
  } = useGetContactHintQuery(appId, { skip: check });

  const [
    generateOtp,
    {
      data: generateOtpData,
      isLoading: generateOtpLoading,
      isError: generateOtpIsError,
      isFetching: generateOtpIsFetching,
      isSuccess: otpGenerated,
      error: generateOtpError,
    },
  ] = useGenerateOtpMutation();

  const [
    validateOtp,
    {
      data: verifyOtpData,
      isLoading: verifyOtpLoading,
      isSuccess: isVerifyOtpSuccess,
      error: verifyOtpError,
      isError: isVerifyOtpError,
    },
  ] = useVerifyotpMutation();

  useEffect(() => {
    let urlAppId = searchParams.get("appId");
    if (urlAppId !== "" && urlAppId !== null && openNumberIdModal === false) {
      let isNum = NUMBER_REGEX(urlAppId);
      if (isNum) {
        setOpenNumberIdModal(true);
        setUrlId(urlAppId);
      }
    }
  }, []);

  useEffect(() => {
    if (
      contactHintError !== null &&
      contactHintError !== undefined &&
      isContactHintError
    ) {
      if (contactHintError?.status === 404) {
        enqueueSnackbar(contactHintError?.data?.message || errorMessageText, {
          variant: "error",
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      } else if (contactHintError?.data?.status === 400) {
        enqueueSnackbar(contactHintError?.data?.message || errorMessageText, {
          variant: "error",
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
    }
  }, [contactHintError]);

  useEffect(() => {
    if (
      generateOtpError !== null &&
      generateOtpError !== undefined &&
      generateOtpIsError
    ) {
      if (generateOtpError?.status === 404) {
        enqueueSnackbar(generateOtpError?.data?.message || errorMessageText, {
          variant: "error",
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
        setAppId("");
      } else if (generateOtpError?.data?.status === 400) {
        enqueueSnackbar(generateOtpError?.data?.message || errorMessageText, {
          variant: "error",
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
    }
  }, [generateOtpError]);

  useEffect(() => {
    if (
      verifyOtpError !== null &&
      verifyOtpError !== undefined &&
      isVerifyOtpError
    ) {
      if (verifyOtpError?.status === 404) {
        enqueueSnackbar(verifyOtpError?.data?.message || errorMessageText, {
          variant: "error",
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      } else if (verifyOtpError?.data?.status === 400) {
        enqueueSnackbar(verifyOtpError?.data?.message || errorMessageText, {
          variant: "error",
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
    }
  }, [verifyOtpError]);

  useEffect(() => {
    if (
      contactHintLoading ||
      generateOtpLoading ||
      verifyOtpLoading ||
      contactHintFetching
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    contactHintLoading,
    generateOtpLoading,
    verifyOtpLoading,
    contactHintFetching,
  ]);

  useEffect(() => {
    if (appId !== "" && check) {
      setCheck(false);
    } else if (appId === "" && !check) {
      setCheck(true);
    }
  }, [appId, check]);

  useEffect(() => {
    if (
      contactHintData?.status === 200 &&
      contactHintData?.data?.phone_number &&
      !check &&
      !isContactHintError &&
      !contactHintFetching &&
      contactHintIsSuccess
    ) {
      setPhoneNumber(contactHintData?.data?.phone_number);
      setOpenNumberIdModal(false);
      setOpenNumberFoundModal(true);
    }
  }, [
    contactHintData,
    check,
    isContactHintError,
    contactHintFetching,
    contactHintIsSuccess,
  ]);

  useEffect(() => {
    if (generateOtpData !== null && generateOtpData !== undefined) {
      if (!openOtpModal && !resendOtp) {
        setOpenNumberFoundModal(false);
        setOpenPhoneNumberModal(false);
        setOpenOtpModal(true);
      } else {
        setResendOtp(true);
      }
    }
  }, [generateOtpData]);

  useEffect(() => {
    if (verifyOtpData !== null && verifyOtpData !== undefined) {
      moengageInitialize();
      if (!appId) {
        moengage.add_unique_user_id(phoneNumber !== null ? phoneNumber : "");
        moengage.add_mobile(phoneNumber !== null ? phoneNumber : "");
      } else {
        moengage.add_unique_user_id(
          generateOtpData?.phone_number !== null
            ? generateOtpData?.phone_number
            : ""
        );
        moengage.add_mobile(
          generateOtpData?.phone_number !== null
            ? generateOtpData?.phone_number
            : ""
        );
      }
      moengage.add_first_name(
        verifyOtpData?.user?.firstname !== null
          ? verifyOtpData?.user?.firstname
          : ""
      );
      moengage.add_last_name(
        verifyOtpData?.user?.lastname !== null
          ? verifyOtpData?.user?.lastname
          : ""
      );
      moengage.add_email(
        verifyOtpData?.user?.email !== null ? verifyOtpData?.user?.email : ""
      );

      sessionStorage.token = verifyOtpData?.access_token;
      sessionStorage.appId = verifyOtpData?.loan_id;
      sessionStorage.username =
        verifyOtpData?.user?.firstname !== null
          ? verifyOtpData?.user?.firstname
          : "" + " " + verifyOtpData?.user?.lastname !== null
          ? verifyOtpData?.user?.lastname
          : "";
      router.push("/lpl");
    }
  }, [verifyOtpData]);

  //FUNCTIONS
  const handlePhoneNumberModalClose = (bool) => {
    setOpenPhoneNumberModal(bool);
  };
  const handleNumberIdModalClose = (bool) => {
    setOpenNumberIdModal(bool);
    setAppId("");
    setCheck(true);
  };
  const handleNumberFoundModalClose = (bool) => {
    setOpenNumberFoundModal(bool);
    setAppId("");
    setCheck(true);
  };
  const handleOtpModalClose = (bool) => {
    setOpenOtpModal(bool);

    setAppId("");
    setCheck(true);
  };

  const handleVerifyPhoneNumber = (phoneNumber) => {
    generateOtp({ phone_number: phoneNumber });
    setPhoneNumber(phoneNumber);
  };

  const handleVerifyAppId = (appId) => {
    setAppId(appId);
  };

  const handleHitSendOtp = () => {
    if (appId !== "") {
      generateOtp({ phone_number: phoneNumber, app_id: appId });
    } else {
      generateOtp({ phone_number: phoneNumber });
    }
  };

  const handleVerifyOtp = (bool, otp) => {
    validateOtp({
      phone_number: phoneNumber,
      app_id: appId,
      otp: otp,
    });
  };
  return (
    <>
      <LoaderComponent open={loading} />
      <Grid container justifyContent="center">
        <Grid item sm={12} md={6} lg={6}>
          <Stack mt={8} p={6} spacing={2} justifyContent="space-between">
            <Grid container justifyContent={"center"}>
              <Image
                src="/logo.svg"
                width={200}
                height={200}
                alt="Hero FinCorp"
                priority
              />
            </Grid>

            <Typography variant="h4" fontWeight={700} textAlign={"center"}>
              {"Log in with"}
            </Typography>

            <SubmitButtonComponent
              variant={"outlined"}
              type={"submit"}
              buttonStyle={{
                color: "green",
                borderRadius: 2.5,
                borderColor: "green",
                borderWidth: "2",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setOpenPhoneNumberModal(true);
              }}
            >
              Registered Mobile Number
            </SubmitButtonComponent>

            <SubmitButtonComponent
              variant={"outlined"}
              type={"submit"}
              buttonStyle={{
                color: "green",
                borderRadius: 2.5,
                borderColor: "green",
                borderWidth: "2",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setOpenNumberIdModal(true);
              }}
            >
              Loan Application ID
            </SubmitButtonComponent>

            <PhoneNumberModal
              open={openPhoneNumberModal}
              handleModalClose={handlePhoneNumberModalClose}
              handleVerifyPhoneNumber={handleVerifyPhoneNumber}
              phone={phoneNumber}
            />

            <NumberIdModal
              open={openNumberIdModal}
              handleModalClose={handleNumberIdModalClose}
              handleVerifyAppId={handleVerifyAppId}
              appId={appId}
              urlId={urlId}
              setAppId={setAppId}
              error={contactHintError}
            />

            <NumberFoundModal
              open={openNumberFoundModal}
              handleModalClose={handleNumberFoundModalClose}
              hitSendOtp={handleHitSendOtp}
              phoneNumber={phoneNumber}
            />

            <OtpModal
              open={openOtpModal}
              handleModalClose={handleOtpModalClose}
              verifyOtp={handleVerifyOtp}
              hitResendOtp={handleHitSendOtp}
              resendOtp={resendOtp}
              setResendOtp={setResendOtp}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginScreen;
