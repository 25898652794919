import moengage from "@moengage/web-sdk";

export const moengageInitialize = () => {
  moengage.initialize({
    app_id: `${process.env.NEXT_PUBLIC_MOENGAGE_TESTING}`,
    cluster: 'dc_3',
    debug_logs: 1,
    swPath: '/serviceworker.js',
    enableSPA: true,
  });
}

export const moengageEventFunctions = (event, eventName) => {
  moengage.add_user_attribute(eventName, eventName);
  moengage.track_event(event, {
    eventName
  });
}

export const handleMoEngageEventTracking = (stageName) => {
  switch (stageName) {
    case null:
    case 'Flow_Started':
      moengageEventFunctions("OFFER_DETAILS", "LPL_WEB_Offer_Selection");
      break;

    case 'address_conformation':
      moengageEventFunctions("ADDRESS_DETAILS", "LPL_WEB_Address_Details");
      break;

    case 'digikyc':
    case 'digikyc_full':
      moengageEventFunctions("HYPERVERGE_DETAILS", "LPL_WEB_Face_Verification_Journey_Started");
      break;

    case 'pan_verification':
      moengageEventFunctions("HYPERVERGE_DETAILS", "LPL_WEB_Pan_Verification_Journey_Started");
      break;

    case "bank_account_details":
      moengageEventFunctions("BANK_DETAILS", "LPL_WEB_Bank_Details_Validation");
      break;

    case 'change_bank_details':
      moengageEventFunctions("BANK_DETAILS", "LPL_WEB_Add_New_Bank_Details");
      break;

    case 'insurance_details':
      moengageEventFunctions("INSURANCE_DETAILS", "LPL_WEB_Insurance_Cross_sell");
      break;

    case 'nominee_details':
      moengageEventFunctions("NOMINEE_DETAILS", "LPL_WEB_Nominee_Details");
      break;

    case 'create_mandate':
    case "mandate_status_check":
      moengageEventFunctions("MANDATE", "LPL_WEB_Repayment_Setup_Started");
      break;

    case 'e_sign':
      moengageEventFunctions("ESIGN", "LPL_WEB_ESign_Started");
      break;

    case 'congratulation':
      moengageEventFunctions("CONGRATULATIONS", "LPL_WEB_Journey_Completed");
      break;

    default:
      console.log(`Invalid event type: ${stageName}`);
      break;
  }
}
